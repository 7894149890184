import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

const NewsList = ({ data, limit }) => {
  const news = data.allDataYaml.nodes.filter(n => n.news !== null)[0].news.slice(0, limit);
  return (
    <section className="news-list">
      <div className="container">
        <h2>Press</h2>
        <div className="columns">
          <div className="column is-10 is-offset-2">
            {news.map((n, i) => (
              <article key={i} className="news-item columns">
                <div className="column is-9">
                  <p className="news-item__meta">
                    {n.date} {`//`} <em>{n.publication}</em>
                  </p>
                  <h3>{n.title}</h3>
                </div>
                <div className="column is-3 news-item__link">
                  <a
                    className="primary-link"
                    href={n.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {n.linkTitle ? n.linkTitle : 'Read more'}
                  </a>
                </div>
              </article>
            ))}
          </div>
        </div>

        {limit && (
          <Link className="arrow-link next" to="/resources/news">
            View all
          </Link>
        )}
      </div>
    </section>
  );
};

export default ({ limit }) => (
  <StaticQuery
    query={graphql`
      query News {
        allDataYaml {
          nodes {
            news {
              title
              url
              publication
              date(formatString: "MMMM D, YYYY")
              linkTitle
            }
          }
        }
      }
    `}
    render={data => <NewsList data={data} limit={limit} />}
  />
);
