import React from "react";
import Layout from "../../components/Layout";
import NewsList from "../../components/NewsList";
import ResourceList from "../../components/ResourceList";

export const ResourcesPageTemplate = ({ title }) => {
  return (
    <section>
      <div className="hero">
      </div>
      <NewsList limit={4} />
      <ResourceList />
    </section>
  );
};

const ResourcesPage = () => {
  return (
    <Layout pageId="resources" metadata={{ title: "Resources" }}>
      <ResourcesPageTemplate />
    </Layout>
  );
};

export default ResourcesPage;
