import React from "react";
import { StaticQuery, graphql } from "gatsby";

const ResourceList = ({ data }) => {
  const resources = data.allDataYaml.nodes.filter(n => n.resources !== null)[0].resources;
  return (
    <section className="news-list resource-list">
      <div className="container">
        <h2 >Resources</h2>
        <div className="columns">
          <div className="column is-10 is-offset-2">
            {resources.map((r, i) => (
              <article key={i} className="news-item columns">
                <div className="column is-9">

                  <h3>{r.title}</h3>
                </div>
                <div className="column is-3 news-item__link">
                  <a
                    className="primary-link"
                    href={r.pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query Resources {
        allDataYaml {
          nodes {
            resources {
              title
              pdf
              image
            }
          }
        }
      }
    `}
    render={data => <ResourceList data={data} {...props} />}
  />
);
